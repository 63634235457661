<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script>
import highcharts from "highcharts";
import moment from "moment";
require("highcharts/highcharts-more.js")(highcharts);
export default {
  props: {
    title: {
      type: String,
      require: false,
      default: "",
    },
    series: {
      type: [Object, Array, Function, undefined],
      require: false,
      default: null,
    },
    lastDate: {
      type: [String, undefined],
      require: false,
      default: null,
    },
    categories: {
      type: [Object, Array, Function, undefined],
      require: false,
      default: null,
    },
  },
  watch: {
    title() {
      this.setTitle();
    },
    series() {
      this.setSeries();
    },
    categories() {
      this.setCategories();
    },
  },
  mounted() {
    this.setTitle();
    this.setSeries();
    this.setCategories();
  },
  methods: {
    setTitle() {
      this.chartOptions.title.text = this.title;
    },
    setSeries() {
      if (this.series == null) return;
      this.chartOptions.series = this.series;
    },
    setCategories() {
      if (this.categories != null) {
        this.chartOptions.xAxis.categories = this.categories;
        return;
      }
      const categories = [];
      const startDate = moment(moment().subtract(3, "month")).date(1).day(1);
      const endDate =
        this.lastDate == null ? moment().day(1) : moment(this.lastDate).day(1);
      while (startDate.isBefore(endDate)) {
        categories.push(startDate.format("yyyy-MM-DD"));
        startDate.add(7, "days");
      }
      categories.push(endDate.format("yyyy-MM-DD"));
      this.chartOptions.xAxis.categories = categories;
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "column",
          height: "330px",
        },
        title: {
          text: "",
        },

        xAxis: {
          categories: [],
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: "Cases",
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} cases</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
        },
        series: [
          {
            name: "Cases",
            data: [1, 0, 3, 4, 3, 5, 2, 1, 0, 2, 3, 4],
          },
          // {
          //   name: "Fulfillment",
          //   data: [1, 2, 1, 1, 3, 2, 1, 1, 3, 1, 3, 2],
          // },
          // {
          //   name: "Administrative",
          //   data: [0, 0, 0, 0, 1, 0, 0, 0, 1, 1, 0, 1],
          // },
        ],
      },
    };
  },
};
</script>
